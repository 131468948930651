.main-loader {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -48px;
  margin-left: -48px;
}
.lds-dual-ring {
  display: inline-block;
  width: 128px;
  height: 128px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 96px;
  height: 96px;
  margin: 1px;
  border-radius: 50%;
  border: 10px solid #19478A;
  border-color: #19478A transparent #19478A transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-dual-ring-mini {
  display: inline-block;
  width: 50px;
  height: 50px;
}
.lds-dual-ring-mini:after {
  content: " ";
  display: block;
  width: 50px;
  height: 50px;
  margin: 1px;
  border-radius: 50%;
  border: 7px solid #19478A;
  border-color: #19478A transparent #19478A transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring-mini {
   0% {
     transform: rotate(0deg);
   }
   100% {
     transform: rotate(360deg);
   }
 }
